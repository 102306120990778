// ../../../react-shim.js
import React from "react";

// src/number-input.tsx
import { useFormControlProps } from "@chakra-ui/form-control";
import { createContext } from "@chakra-ui/react-context";
import {
  chakra,
  forwardRef,
  omitThemingProps,
  useMultiStyleConfig
} from "@chakra-ui/system";

// ../../utilities/shared-utils/src/index.ts
var cx = (...classNames) => classNames.filter(Boolean).join(" ");
var ariaAttr = (condition) => condition ? true : void 0;
function callAllHandlers(...fns) {
  return function func(event) {
    fns.some((fn) => {
      fn == null ? void 0 : fn(event);
      return event == null ? void 0 : event.defaultPrevented;
    });
  };
}

// src/number-input.tsx
import { useMemo as useMemo2 } from "react";

// src/icons.tsx
import { Icon } from "@chakra-ui/icon";
var TriangleDownIcon = (props) => /* @__PURE__ */ React.createElement(Icon, {
  viewBox: "0 0 24 24",
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  fill: "currentColor",
  d: "M21,5H3C2.621,5,2.275,5.214,2.105,5.553C1.937,5.892,1.973,6.297,2.2,6.6l9,12 c0.188,0.252,0.485,0.4,0.8,0.4s0.611-0.148,0.8-0.4l9-12c0.228-0.303,0.264-0.708,0.095-1.047C21.725,5.214,21.379,5,21,5z"
}));
var TriangleUpIcon = (props) => /* @__PURE__ */ React.createElement(Icon, {
  viewBox: "0 0 24 24",
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  fill: "currentColor",
  d: "M12.8,5.4c-0.377-0.504-1.223-0.504-1.6,0l-9,12c-0.228,0.303-0.264,0.708-0.095,1.047 C2.275,18.786,2.621,19,3,19h18c0.379,0,0.725-0.214,0.895-0.553c0.169-0.339,0.133-0.744-0.095-1.047L12.8,5.4z"
}));

// src/use-number-input.ts
import { useCounter } from "@chakra-ui/counter";
import { useEventListener } from "@chakra-ui/react-use-event-listener";
import { useUpdateEffect } from "@chakra-ui/react-use-update-effect";
import { useSafeLayoutEffect } from "@chakra-ui/react-use-safe-layout-effect";
import { useCallbackRef } from "@chakra-ui/react-use-callback-ref";
import { mergeRefs } from "@chakra-ui/react-use-merge-refs";
import { useCallback as useCallback2, useMemo, useRef as useRef2, useState as useState2 } from "react";

// src/use-attr-observer.ts
import { useEffect } from "react";
function useAttributeObserver(ref, attributes, fn, enabled) {
  useEffect(() => {
    if (!ref.current || !enabled)
      return;
    const win = ref.current.ownerDocument.defaultView ?? window;
    const attrs = Array.isArray(attributes) ? attributes : [attributes];
    const obs = new win.MutationObserver((changes) => {
      for (const change of changes) {
        if (change.type === "attributes" && change.attributeName && attrs.includes(change.attributeName)) {
          fn(change);
        }
      }
    });
    obs.observe(ref.current, { attributes: true, attributeFilter: attrs });
    return () => obs.disconnect();
  });
}

// src/use-spinner.ts
import { useInterval } from "@chakra-ui/react-use-interval";
import { useCallback, useEffect as useEffect2, useRef, useState } from "react";
var CONTINUOUS_CHANGE_INTERVAL = 50;
var CONTINUOUS_CHANGE_DELAY = 300;
function useSpinner(increment, decrement) {
  const [isSpinning, setIsSpinning] = useState(false);
  const [action, setAction] = useState(null);
  const [runOnce, setRunOnce] = useState(true);
  const timeoutRef = useRef(null);
  const removeTimeout = () => clearTimeout(timeoutRef.current);
  useInterval(() => {
    if (action === "increment") {
      increment();
    }
    if (action === "decrement") {
      decrement();
    }
  }, isSpinning ? CONTINUOUS_CHANGE_INTERVAL : null);
  const up = useCallback(() => {
    if (runOnce) {
      increment();
    }
    timeoutRef.current = setTimeout(() => {
      setRunOnce(false);
      setIsSpinning(true);
      setAction("increment");
    }, CONTINUOUS_CHANGE_DELAY);
  }, [increment, runOnce]);
  const down = useCallback(() => {
    if (runOnce) {
      decrement();
    }
    timeoutRef.current = setTimeout(() => {
      setRunOnce(false);
      setIsSpinning(true);
      setAction("decrement");
    }, CONTINUOUS_CHANGE_DELAY);
  }, [decrement, runOnce]);
  const stop = useCallback(() => {
    setRunOnce(true);
    setIsSpinning(false);
    removeTimeout();
  }, []);
  useEffect2(() => {
    return () => removeTimeout();
  }, []);
  return { up, down, stop, isSpinning };
}

// src/use-number-input.ts
var FLOATING_POINT_REGEX = /^[Ee0-9+\-.]$/;
function isFloatingPointNumericCharacter(character) {
  return FLOATING_POINT_REGEX.test(character);
}
function isValidNumericKeyboardEvent(event, isValid) {
  if (event.key == null)
    return true;
  const isModifierKey = event.ctrlKey || event.altKey || event.metaKey;
  const isSingleCharacterKey = event.key.length === 1;
  if (!isSingleCharacterKey || isModifierKey)
    return true;
  return isValid(event.key);
}
function useNumberInput(props = {}) {
  const {
    focusInputOnChange = true,
    clampValueOnBlur = true,
    keepWithinRange = true,
    min = Number.MIN_SAFE_INTEGER,
    max = Number.MAX_SAFE_INTEGER,
    step: stepProp = 1,
    isReadOnly,
    isDisabled,
    isRequired,
    isInvalid,
    pattern = "[0-9]*(.[0-9]+)?",
    inputMode = "decimal",
    allowMouseWheel,
    id,
    onChange: _,
    precision,
    name,
    "aria-describedby": ariaDescBy,
    "aria-label": ariaLabel,
    "aria-labelledby": ariaLabelledBy,
    onFocus: onFocusProp,
    onBlur: onBlurProp,
    onInvalid: onInvalidProp,
    getAriaValueText: getAriaValueTextProp,
    isValidCharacter: isValidCharacterProp,
    format: formatValue,
    parse: parseValue,
    ...htmlProps
  } = props;
  const onFocus = useCallbackRef(onFocusProp);
  const onBlur = useCallbackRef(onBlurProp);
  const onInvalid = useCallbackRef(onInvalidProp);
  const isValidCharacter = useCallbackRef(isValidCharacterProp ?? isFloatingPointNumericCharacter);
  const getAriaValueText = useCallbackRef(getAriaValueTextProp);
  const counter = useCounter(props);
  const {
    update: updateFn,
    increment: incrementFn,
    decrement: decrementFn
  } = counter;
  const [isFocused, setFocused] = useState2(false);
  const isInteractive = !(isReadOnly || isDisabled);
  const inputRef = useRef2(null);
  const inputSelectionRef = useRef2(null);
  const incrementButtonRef = useRef2(null);
  const decrementButtonRef = useRef2(null);
  const sanitize = useCallback2((value) => value.split("").filter(isValidCharacter).join(""), [isValidCharacter]);
  const parse = useCallback2((value) => (parseValue == null ? void 0 : parseValue(value)) ?? value, [parseValue]);
  const format = useCallback2((value) => ((formatValue == null ? void 0 : formatValue(value)) ?? value).toString(), [formatValue]);
  useUpdateEffect(() => {
    if (counter.valueAsNumber > max) {
      onInvalid == null ? void 0 : onInvalid("rangeOverflow", format(counter.value), counter.valueAsNumber);
    } else if (counter.valueAsNumber < min) {
      onInvalid == null ? void 0 : onInvalid("rangeOverflow", format(counter.value), counter.valueAsNumber);
    }
  }, [counter.valueAsNumber, counter.value, format, onInvalid]);
  useSafeLayoutEffect(() => {
    if (!inputRef.current)
      return;
    const notInSync = inputRef.current.value != counter.value;
    if (notInSync) {
      const parsedInput = parse(inputRef.current.value);
      counter.setValue(sanitize(parsedInput));
    }
  }, [parse, sanitize]);
  const increment = useCallback2((step = stepProp) => {
    if (isInteractive) {
      incrementFn(step);
    }
  }, [incrementFn, isInteractive, stepProp]);
  const decrement = useCallback2((step = stepProp) => {
    if (isInteractive) {
      decrementFn(step);
    }
  }, [decrementFn, isInteractive, stepProp]);
  const spinner = useSpinner(increment, decrement);
  useAttributeObserver(incrementButtonRef, "disabled", spinner.stop, spinner.isSpinning);
  useAttributeObserver(decrementButtonRef, "disabled", spinner.stop, spinner.isSpinning);
  const onChange = useCallback2((event) => {
    const evt = event.nativeEvent;
    if (evt.isComposing)
      return;
    const parsedInput = parse(event.currentTarget.value);
    updateFn(sanitize(parsedInput));
    inputSelectionRef.current = {
      start: event.currentTarget.selectionStart,
      end: event.currentTarget.selectionEnd
    };
  }, [updateFn, sanitize, parse]);
  const _onFocus = useCallback2((event) => {
    var _a;
    onFocus == null ? void 0 : onFocus(event);
    if (!inputSelectionRef.current)
      return;
    event.target.selectionStart = inputSelectionRef.current.start ?? ((_a = event.currentTarget.value) == null ? void 0 : _a.length);
    event.currentTarget.selectionEnd = inputSelectionRef.current.end ?? event.currentTarget.selectionStart;
  }, [onFocus]);
  const onKeyDown = useCallback2((event) => {
    if (event.nativeEvent.isComposing)
      return;
    if (!isValidNumericKeyboardEvent(event, isValidCharacter)) {
      event.preventDefault();
    }
    const stepFactor = getStepFactor(event) * stepProp;
    const eventKey = event.key;
    const keyMap = {
      ArrowUp: () => increment(stepFactor),
      ArrowDown: () => decrement(stepFactor),
      Home: () => updateFn(min),
      End: () => updateFn(max)
    };
    const action = keyMap[eventKey];
    if (action) {
      event.preventDefault();
      action(event);
    }
  }, [isValidCharacter, stepProp, increment, decrement, updateFn, min, max]);
  const getStepFactor = (event) => {
    let ratio = 1;
    if (event.metaKey || event.ctrlKey) {
      ratio = 0.1;
    }
    if (event.shiftKey) {
      ratio = 10;
    }
    return ratio;
  };
  const ariaValueText = useMemo(() => {
    const text = getAriaValueText == null ? void 0 : getAriaValueText(counter.value);
    if (text != null)
      return text;
    const defaultText = counter.value.toString();
    return !defaultText ? void 0 : defaultText;
  }, [counter.value, getAriaValueText]);
  const validateAndClamp = useCallback2(() => {
    let next = counter.value;
    if (counter.value === "")
      return;
    const valueStartsWithE = /^[eE]/.test(counter.value.toString());
    if (valueStartsWithE) {
      counter.setValue("");
    } else {
      if (counter.valueAsNumber < min) {
        next = min;
      }
      if (counter.valueAsNumber > max) {
        next = max;
      }
      counter.cast(next);
    }
  }, [counter, max, min]);
  const onInputBlur = useCallback2(() => {
    setFocused(false);
    if (clampValueOnBlur) {
      validateAndClamp();
    }
  }, [clampValueOnBlur, setFocused, validateAndClamp]);
  const focusInput = useCallback2(() => {
    if (focusInputOnChange) {
      requestAnimationFrame(() => {
        var _a;
        (_a = inputRef.current) == null ? void 0 : _a.focus();
      });
    }
  }, [focusInputOnChange]);
  const spinUp = useCallback2((event) => {
    event.preventDefault();
    spinner.up();
    focusInput();
  }, [focusInput, spinner]);
  const spinDown = useCallback2((event) => {
    event.preventDefault();
    spinner.down();
    focusInput();
  }, [focusInput, spinner]);
  useEventListener(() => inputRef.current, "wheel", (event) => {
    var _a;
    const doc = ((_a = inputRef.current) == null ? void 0 : _a.ownerDocument) ?? document;
    const isInputFocused = doc.activeElement === inputRef.current;
    if (!allowMouseWheel || !isInputFocused)
      return;
    event.preventDefault();
    const stepFactor = getStepFactor(event) * stepProp;
    const direction = Math.sign(event.deltaY);
    if (direction === -1) {
      increment(stepFactor);
    } else if (direction === 1) {
      decrement(stepFactor);
    }
  }, { passive: false });
  const getIncrementButtonProps = useCallback2((props2 = {}, ref = null) => {
    const disabled = isDisabled || keepWithinRange && counter.isAtMax;
    return {
      ...props2,
      ref: mergeRefs(ref, incrementButtonRef),
      role: "button",
      tabIndex: -1,
      onPointerDown: callAllHandlers(props2.onPointerDown, (event) => {
        if (event.button !== 0 || disabled)
          return;
        spinUp(event);
      }),
      onPointerLeave: callAllHandlers(props2.onPointerLeave, spinner.stop),
      onPointerUp: callAllHandlers(props2.onPointerUp, spinner.stop),
      disabled,
      "aria-disabled": ariaAttr(disabled)
    };
  }, [counter.isAtMax, keepWithinRange, spinUp, spinner.stop, isDisabled]);
  const getDecrementButtonProps = useCallback2((props2 = {}, ref = null) => {
    const disabled = isDisabled || keepWithinRange && counter.isAtMin;
    return {
      ...props2,
      ref: mergeRefs(ref, decrementButtonRef),
      role: "button",
      tabIndex: -1,
      onPointerDown: callAllHandlers(props2.onPointerDown, (event) => {
        if (event.button !== 0 || disabled)
          return;
        spinDown(event);
      }),
      onPointerLeave: callAllHandlers(props2.onPointerLeave, spinner.stop),
      onPointerUp: callAllHandlers(props2.onPointerUp, spinner.stop),
      disabled,
      "aria-disabled": ariaAttr(disabled)
    };
  }, [counter.isAtMin, keepWithinRange, spinDown, spinner.stop, isDisabled]);
  const getInputProps = useCallback2((props2 = {}, ref = null) => ({
    name,
    inputMode,
    type: "text",
    pattern,
    "aria-labelledby": ariaLabelledBy,
    "aria-label": ariaLabel,
    "aria-describedby": ariaDescBy,
    id,
    disabled: isDisabled,
    ...props2,
    readOnly: props2.readOnly ?? isReadOnly,
    "aria-readonly": props2.readOnly ?? isReadOnly,
    "aria-required": props2.required ?? isRequired,
    required: props2.required ?? isRequired,
    ref: mergeRefs(inputRef, ref),
    value: format(counter.value),
    role: "spinbutton",
    "aria-valuemin": min,
    "aria-valuemax": max,
    "aria-valuenow": Number.isNaN(counter.valueAsNumber) ? void 0 : counter.valueAsNumber,
    "aria-invalid": ariaAttr(isInvalid ?? counter.isOutOfRange),
    "aria-valuetext": ariaValueText,
    autoComplete: "off",
    autoCorrect: "off",
    onChange: callAllHandlers(props2.onChange, onChange),
    onKeyDown: callAllHandlers(props2.onKeyDown, onKeyDown),
    onFocus: callAllHandlers(props2.onFocus, _onFocus, () => setFocused(true)),
    onBlur: callAllHandlers(props2.onBlur, onBlur, onInputBlur)
  }), [
    name,
    inputMode,
    pattern,
    ariaLabelledBy,
    ariaLabel,
    format,
    ariaDescBy,
    id,
    isDisabled,
    isRequired,
    isReadOnly,
    isInvalid,
    counter.value,
    counter.valueAsNumber,
    counter.isOutOfRange,
    min,
    max,
    ariaValueText,
    onChange,
    onKeyDown,
    _onFocus,
    onBlur,
    onInputBlur
  ]);
  return {
    value: format(counter.value),
    valueAsNumber: counter.valueAsNumber,
    isFocused,
    isDisabled,
    isReadOnly,
    getIncrementButtonProps,
    getDecrementButtonProps,
    getInputProps,
    htmlProps
  };
}

// src/number-input.tsx
var [NumberInputStylesProvider, useNumberInputStyles] = createContext({
  name: `NumberInputStylesContext`,
  errorMessage: `useNumberInputStyles returned is 'undefined'. Seems you forgot to wrap the components in "<NumberInput />" `
});
var [NumberInputProvider, useNumberInputContext] = createContext({
  name: "NumberInputContext",
  errorMessage: "useNumberInputContext: `context` is undefined. Seems you forgot to wrap number-input's components within <NumberInput />"
});
var NumberInput = forwardRef(function NumberInput2(props, ref) {
  const styles = useMultiStyleConfig("NumberInput", props);
  const ownProps = omitThemingProps(props);
  const controlProps = useFormControlProps(ownProps);
  const { htmlProps, ...context } = useNumberInput(controlProps);
  const ctx = useMemo2(() => context, [context]);
  return /* @__PURE__ */ React.createElement(NumberInputProvider, {
    value: ctx
  }, /* @__PURE__ */ React.createElement(NumberInputStylesProvider, {
    value: styles
  }, /* @__PURE__ */ React.createElement(chakra.div, {
    ...htmlProps,
    ref,
    className: cx("chakra-numberinput", props.className),
    __css: {
      position: "relative",
      zIndex: 0,
      ...styles.root
    }
  })));
});
NumberInput.displayName = "NumberInput";
var NumberInputStepper = forwardRef(function NumberInputStepper2(props, ref) {
  const styles = useNumberInputStyles();
  return /* @__PURE__ */ React.createElement(chakra.div, {
    "aria-hidden": true,
    ref,
    ...props,
    __css: {
      display: "flex",
      flexDirection: "column",
      position: "absolute",
      top: "0",
      insetEnd: "0px",
      margin: "1px",
      height: "calc(100% - 2px)",
      zIndex: 1,
      ...styles.stepperGroup
    }
  });
});
NumberInputStepper.displayName = "NumberInputStepper";
var NumberInputField = forwardRef(function NumberInputField2(props, ref) {
  const { getInputProps } = useNumberInputContext();
  const input = getInputProps(props, ref);
  const styles = useNumberInputStyles();
  return /* @__PURE__ */ React.createElement(chakra.input, {
    ...input,
    className: cx("chakra-numberinput__field", props.className),
    __css: {
      width: "100%",
      ...styles.field
    }
  });
});
NumberInputField.displayName = "NumberInputField";
var StyledStepper = chakra("div", {
  baseStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    transitionProperty: "common",
    transitionDuration: "normal",
    userSelect: "none",
    cursor: "pointer",
    lineHeight: "normal"
  }
});
var NumberDecrementStepper = forwardRef(function NumberDecrementStepper2(props, ref) {
  const styles = useNumberInputStyles();
  const { getDecrementButtonProps } = useNumberInputContext();
  const decrement = getDecrementButtonProps(props, ref);
  return /* @__PURE__ */ React.createElement(StyledStepper, {
    ...decrement,
    __css: styles.stepper
  }, props.children ?? /* @__PURE__ */ React.createElement(TriangleDownIcon, null));
});
NumberDecrementStepper.displayName = "NumberDecrementStepper";
var NumberIncrementStepper = forwardRef(function NumberIncrementStepper2(props, ref) {
  const { getIncrementButtonProps } = useNumberInputContext();
  const increment = getIncrementButtonProps(props, ref);
  const styles = useNumberInputStyles();
  return /* @__PURE__ */ React.createElement(StyledStepper, {
    ...increment,
    __css: styles.stepper
  }, props.children ?? /* @__PURE__ */ React.createElement(TriangleUpIcon, null));
});
NumberIncrementStepper.displayName = "NumberIncrementStepper";
export {
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  StyledStepper,
  useNumberInput,
  useNumberInputStyles
};
